import React from 'react';
import { ESTIMATION, TIME_OPTIONS, CLOCK_ICON as clockIcon } from '../../constants';

interface IProps {
  timeEstimation: number;
}

// TODO - we need to make a function that sets the correct time option

const EstimatedTime = ({ timeEstimation }: IProps) => (
  <div className='flex items-center h-[1.25rem]'>
    <img src={clockIcon} alt='clock' className='h-[1.25rem] w-[1.25rem] mr-[0.625rem]' />
    <div className='font-aspira700 text-[0.875rem] mr-[0.5rem]'>{`${timeEstimation} ${TIME_OPTIONS.MINUTES}`}</div>
    <div className='font-aspira400 text-[0.875rem]'>{ESTIMATION}</div>
  </div>
);

export default EstimatedTime;
