import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  LeftPlayer, PlayerTopBar, RightPlayer,
} from '../../components';
import {
  CHANGE_SCREEN_OPTION,
  FIRST_PAGE_IN_MODULE,
  FIRST_POSITION,
} from '../../constants';
import { PLAYER, SYLLABUS } from '../route_links';
import { navigationBarStore } from '../../store/NavigationBarStore';
import { courseStructureStore } from '../../store/CourseStructure';
import { setCourseCurriculum } from '../../store/CourseCurriculum';
import {
  IModule,
  IScreen,
} from '../../interfaces';

const Player = () => {
  const navigate = useNavigate();
  const { courseId, moduleId, screenNum } = useParams();

  const { FORWARD_ARROW, BACK_ARROW } = CHANGE_SCREEN_OPTION;

  const [moduleContent, setModuleContent] = useState<IModule>();
  const [screenContent, setScreenContent] = useState<IScreen>();

  const [userPosition, setUserPosition] = navigationBarStore((state) => [state.userPosition, state.setUserPosition]);
  const structure = courseStructureStore((state) => state.structure);
  const [curriculum] = setCourseCurriculum((state) => [state.curriculum]);

  const getModule = (mod: string) => {
    const module = curriculum.modules.find((item: any) => item.id === mod);
    setModuleContent(module);
    return module;
  };

  useEffect(() => {
    getModule(moduleId!);
  }, []);

  useEffect(() => {
    if (moduleContent) {
      setScreenContent(moduleContent.screens[+screenNum!]);
    }
  }, [moduleContent, screenNum]);

  const handleScreenChange = async (mode: string) => {
    if (moduleContent && screenContent) {
      const { id, screens } = moduleContent;
      const moduleIndex = +id[id.length - 1];
      switch (mode) {
        case FORWARD_ARROW:
          if (screens.length - 1 === +screenNum! && moduleIndex === structure.length) {
            navigate(generatePath(SYLLABUS, { courseId: courseId! }));
          } else if (screens.length - 1 === +screenNum!) {
            setModuleContent(getModule(structure[moduleIndex].id));
            if (+screenNum! + 1 > userPosition.screenIndex && moduleIndex === userPosition.moduleIndex) {
              setUserPosition({ ...userPosition, moduleIndex: moduleContent.moduleIndex, screenIndex: FIRST_POSITION });
            }
            navigate(generatePath(PLAYER, { courseId: courseId!, moduleId: structure[moduleIndex].id, screenNum: FIRST_PAGE_IN_MODULE }));
          } else if (screens.length - 1 !== +screenNum!) {
            if (+screenNum! + 1 > userPosition.screenIndex && moduleIndex === userPosition.moduleIndex) {
              setUserPosition({ ...userPosition, screenIndex: +screenNum! + 1 });
            }
            navigate(generatePath(PLAYER, { courseId: courseId!, moduleId: moduleId!, screenNum: (+screenNum! + 1).toString() }));
          }
          break;
        case BACK_ARROW:
          if (screenNum! !== FIRST_PAGE_IN_MODULE) {
            navigate(generatePath(PLAYER, { courseId: courseId!, moduleId: moduleId!, screenNum: (+screenNum! - 1).toString() }));
          } else if (screenNum! === FIRST_PAGE_IN_MODULE) {
            setModuleContent(getModule(structure[moduleIndex - 2].id));
            navigate(
              generatePath(
                PLAYER,
                { courseId: courseId!, moduleId: structure[moduleIndex - 2].id, screenNum: (structure[moduleIndex - 2].screensLength! - 1).toString() },
              ),
            );
          }
          break;
        default:
          break;
      }
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className='flex flex-col h-screen'>

      <PlayerTopBar denominator={moduleContent && moduleContent.screens ? moduleContent.screens.length : 0} />

      <div className=''>
        <div className='flex h-screen w-full'>
          <div className='mt-[6.78rem] w-1/2 flex-grow overflow-y-auto border-r border-1-r border-r-[#E2E1E6] scrollbar'>
            <LeftPlayer
              title={screenContent && screenContent.title ? screenContent.title : undefined}
              timeEstimation={screenContent && screenContent.timeEstimation ? screenContent.timeEstimation : undefined}
              markdown={screenContent && screenContent.markdown ? screenContent.markdown : undefined}
            />
          </div>

          <div className='w-1/2 border-l border-1-l border-l-[#E2E1E6] bg-[#FBFAFB]'>
            <RightPlayer
              image={screenContent && screenContent.image ? screenContent.image : undefined}
              quiz={screenContent && screenContent.quiz ? screenContent.quiz : undefined}
              quizTip={screenContent && screenContent.quizTip ? screenContent.quizTip : undefined}
              handleChangeScreen={handleScreenChange}
              showBackBtn={() => moduleContent && moduleContent.moduleIndex === FIRST_POSITION && +screenNum! === FIRST_POSITION}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Player;
