// eslint-disable-next-line import/no-extraneous-dependencies
import { createClient } from 'contentful';
import { PREVIEW_CONTENTFUL_API } from '../constants';

export const createClientDelivery = createClient({
  space: process.env.REACT_APP_SPACE_ID!,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN!,
  environment: process.env.REACT_APP_ENV_CONTENTFUL,
});

export const createClientPreview = createClient({
  space: process.env.REACT_APP_SPACE_ID!,
  accessToken: process.env.REACT_APP_PREVIEW_ACCESS_TOKEN!,
  host: PREVIEW_CONTENTFUL_API,
  environment: process.env.REACT_APP_ENV_CONTENTFUL,
});
