import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactCardFlip from 'react-card-flip';
import {
  Button,
  PlayerImage, Quiz, QuizExplanation,
} from '../index';
import { IQuiz, IQuizTip } from '../../interfaces';
import {
  BACK_BTN, CONTINUE_BTN, QUIZ_TYPES, CHANGE_SCREEN_OPTION,
} from '../../constants';
import arrow from '../../assets/images/skinny-arrow.svg';
import whiteArrow from '../../assets/images/skinny-arrow-white.svg';

interface IProps {
  quizTip?: IQuizTip;
  image?: string;
  quiz?: IQuiz;
  handleChangeScreen: (context: string) => void;
  showBackBtn: any;
}

const RightPlayer = ({
  image, quiz, handleChangeScreen, quizTip, showBackBtn,
}: IProps) => {
  const { moduleId, screenNum } = useParams();
  const [showExplanation, setShowExplanation] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);

  const { MULTIPLE_CHOICE, SURVEY } = QUIZ_TYPES;

  useEffect(() => {
    setShowExplanation(false);
  }, [moduleId, screenNum]);

  return (
    <div className='w-full h-screen'>

      {image && (
        <div className='w-full h-full flex justify-center items-center'>
          <div className='w-full pt-[8.125rem] max-sm:pt-[6rem] flex items-center justify-center'>
            <div className='h-[85%] w-[80%]'>
              <PlayerImage src={image} />
            </div>
          </div>
        </div>
      )}

      {quiz && (
        <div className='flex justify-center h-full w-full [perspective:1000px] max-lg:flex max-lg:justify-center'>
          <div className='w-full pt-[8.125rem] max-sm:pt-[6rem] flex items-center justify-center'>
            <div className='h-[85%] w-[80%] flex justify-center'>
              <ReactCardFlip isFlipped={showExplanation}>
                <div className='w-full h-full'>
                  <Quiz
                    quiz={quiz}
                    handleChangeScreen={handleChangeScreen}
                    handleFlipCard={() => setShowExplanation(!showExplanation)}
                    isExplanationViewed={showExplanation}
                    userAnswered={setIsAnswered}
                  />
                </div>

                <div className='w-full h-full'>
                  <QuizExplanation
                    explanation={quiz.material.explanation}
                    handleFlipCard={() => setShowExplanation(!showExplanation)}
                    isExplanationViewed={showExplanation}
                  />
                </div>
              </ReactCardFlip>
            </div>
          </div>
        </div>
      )}

      {quizTip && (
        <div className='flex justify-center h-full w-full [perspective:1000px] max-lg:flex max-lg:justify-center'>
          <div className='w-full pt-[8.125rem] max-sm:pt-[6rem] flex items-center justify-center'>
            <div className='relative h-[85%] w-[80%] flex justify-center'>
              <ReactCardFlip isFlipped={showExplanation}>
                <QuizExplanation
                  title={quizTip.title}
                  explanation={quizTip.explanation}
                  handleFlipCard={() => setShowExplanation(!showExplanation)}
                  isExplanationViewed
                />

                <div />
              </ReactCardFlip>
            </div>
          </div>
        </div>
      )}

      <div className='h-[8.25rem] px-[3.75rem] bg-white fixed z-10 left-0 bottom-0 flex justify-between items-center w-[50%] border-t border-t-2 border-t-[#E2E1E6] border-r border-r-1 border-r-[#E2E1E6]'>
        <div className={showBackBtn() && 'hidden'}>
          <Button
            className='font-aspira700 text-[1.25rem] underline text-[#928E9F]'
            context={BACK_BTN}
            handleClick={() => handleChangeScreen(CHANGE_SCREEN_OPTION.BACK_ARROW)}
          />
        </div>

        {((quiz?.type === MULTIPLE_CHOICE && isAnswered) || (quiz?.type === SURVEY && isAnswered) || image || quizTip) ? (
          <Button
            className='h-[3.5rem] w-[10.25rem] bg-orange rounded-[6.25rem] text-[1.25rem] font-aspira700 text-white'
            context={CONTINUE_BTN}
            reverse
            icon={whiteArrow}
            handleClick={() => { handleChangeScreen(CHANGE_SCREEN_OPTION.FORWARD_ARROW); }}
          />
        ) : (
          <Button
            className='h-[3.5rem] w-[10.25rem] bg-[#E2E1E6] rounded-[6.25rem] text-[1.25rem] font-aspira700 text-[#767385]'
            context={CONTINUE_BTN}
            reverse
            icon={arrow}
          />
        )}
      </div>

    </div>
  );
};
export default RightPlayer;
