import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  LeftPlayer, RightPlayer, PreviewTopBar,
} from '../../components';
import { IQUIZ, IScreen } from '../../interfaces';
import contentfulService from '../../services/contentfulTools.service';
import { createClientPreview } from '../../services/contentful.service';

const Vision = () => {
  const { screenEntry } = useParams();

  const [screenContent, setScreenContent] = useState<IScreen>();

  const getAnswers = (answers: string[]) => {
    if (answers) {
      answers.map(answer => {
        answer.trim();
        return answer;
      });
      return answers;
    }
    return undefined;
  };

  const getTip = async (id: string) => {
    const tipEntry: any = await contentfulService.getEntry(id);
    if (tipEntry && tipEntry.fields) {
      const tipJson = {
        explanation: {},
        title: 'Tip',
      };
      if (tipEntry.fields.Explanation) tipJson.explanation = tipEntry.fields.Explanation;
      if (tipEntry.fields.tipTitle) tipJson.title = tipEntry.fields.tipTitle;
      return tipJson;
    }
    return null;
  };

  const getQuiz = async (id: string) => {
    const quizEntry: any = await contentfulService.getEntry(id);
    if (quizEntry && quizEntry.fields) {
      const quizJson: IQUIZ = { material: {} };
      if (quizEntry.fields.type) quizJson.type = quizEntry.fields.type;
      if (quizEntry.fields.question) quizJson.material!.question = quizEntry.fields.question;
      if (quizEntry.fields.answers) quizJson.material!.answers = getAnswers(quizEntry.fields.answers);
      if (quizEntry.fields.correctAnswer) quizJson.material!.correctAnswer = quizEntry.fields.correctAnswer;
      if (quizEntry.fields.explanation) quizJson.material!.explanation = quizEntry.fields.explanation;
      return quizJson;
    }
    return null;
  };

  useEffect(() => {
    const data = async () => {
      const entry = await createClientPreview.getEntry(screenEntry!);
      const id = entry.fields.slug;
      const markdown = entry.fields.content ? entry.fields.content : undefined;
      const timeEstimation = undefined;
      const title = entry.fields.title ? entry.fields.title : undefined;
      // @ts-ignore
      const image = entry.fields.image ? `https:${entry.fields.image.fields.file.url}` : undefined;
      // @ts-ignore
      const quiz = entry.fields.quiz ? await getQuiz(entry.fields.quiz.sys.id) : undefined;
      const markdownImage = undefined;
      // @ts-ignore
      const quizTip = entry.fields.tip ? await getTip(entry.fields.tip.sys.id) : undefined;
      // @ts-ignore
      // eslint-disable-next-line object-curly-newline
      const screen: IScreen = { id, image, markdownImage, markdown, title, quizTip, quiz, timeEstimation };
      setScreenContent(screen);
    };
    data();
  }, []);

  return (
    <div className='flex flex-col h-screen'>

      <PreviewTopBar />

      <div className=''>
        <div className='flex h-screen w-full'>
          <div className='mt-[6.78rem] w-1/2 flex-grow overflow-y-auto border-r border-1-r border-r-[#E2E1E6] scrollbar'>
            <LeftPlayer
              title={screenContent && screenContent.title ? screenContent.title : undefined}
              timeEstimation={screenContent && screenContent.timeEstimation ? screenContent.timeEstimation : undefined}
              markdown={screenContent && screenContent.markdown ? screenContent.markdown : undefined}
            />
          </div>

          <div className='w-1/2 border-l border-1-l border-l-[#E2E1E6] bg-[#FBFAFB]'>
            <RightPlayer
              image={screenContent && screenContent.image ? screenContent.image : undefined}
              quiz={screenContent && screenContent.quiz ? screenContent.quiz : undefined}
              quizTip={screenContent && screenContent.quizTip ? screenContent.quizTip : undefined}
              handleChangeScreen={() => {}}
              showBackBtn={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
