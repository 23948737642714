import { create } from 'zustand';
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware';
import { del, get, set } from 'idb-keyval';
import { IPlayerPosition } from '../../interfaces';

type State = {
  userPosition: IPlayerPosition
}

type Action = {
  setUserPosition: (store: State['userPosition']) => void
}

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => (await get(name)) || null,
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

export const navigationBarStore = create<State & Action>()(
  persist(
    // eslint-disable-next-line no-shadow
    (set) => ({
      userPosition: { courseId: '', moduleIndex: 0, screenIndex: 0 },
      setUserPosition: (userPosition) => set(() => ({ userPosition })),
    }),
    {
      name: 'userPosition',
      storage: createJSONStorage(() => storage),
    },
  ),
);
