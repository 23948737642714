import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const App = () => {
  useEffect(() => {
  }, []);
  return (
    <Outlet />
  );
};

export default App;
