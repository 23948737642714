import React from 'react';
import homeIcon from '../../assets/images/player-home-btn.svg';
import logo from '../../assets/images/chegg-logo.svg';

const PreviewTopBar = () => (
  <div className='fixed z-10 top-0 left-0 right-0 h-[8.125rem] max-sm:h-[6rem] border border-b-1 max-sm:border-b-2 border-b-[#E2E1E6] bg-white'>
    <div
      className='absolute left-4 h-full flex cursor-pointer sm:top-[3rem] max-sm:items-center'
      aria-hidden
      onClick={() => {}}
    >
      <div className='w-[3rem] h-[3rem] rounded-full border border-1 border-[#302C41] flex justify-center items-center'>
        <img className='h-[1.5rem] w-[1.5rem]' src={homeIcon} alt='home' />
      </div>
    </div>

    <div className='flex flex-col h-full items-center max-sm:items-end max-sm:justify-center max-sm:mr-[1rem]'>
      <img src={logo} alt='logo' className='mt-[0.5rem] mb-[1.938rem] max-sm:hidden' />
      <div className='flex items-center justify-center' />
    </div>
  </div>
);

export default PreviewTopBar;
