import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document } from '@contentful/rich-text-types';
import { STATIC_QUIZ_STRINGS } from '../../constants';
import { PlayerContent } from '../index';

interface IProps {
  title?: string;
  explanation: Document;
  handleFlipCard: () => void;
  isExplanationViewed: boolean;
}

const QuizExplanation = ({
  explanation, handleFlipCard, title, isExplanationViewed,
}: IProps) => {
  const { BACK_TO_QUESTION, EXPLANATION } = STATIC_QUIZ_STRINGS;

  return (
    <div className={`w-full h-full relative ${!isExplanationViewed ? 'hidden' : ''}`}>
      <div className='bg-white shadow-lastStudiedBox rounded-[0.5rem] h-full w-full p-[3.5rem] overflow-y-auto'>
        <div className='fixed top-0 left-1 w-[99%] h-[3.75rem] bg-white z-50'>
          <div className='absolute bottom-[0.25rem] w-[4rem] h-[0.468rem] bg-orange rounded-[62.5rem] ml-[3rem]' />
        </div>

        <div className='pb-[5.3rem]'>
          {title ? <div className='text-[1.375rem] font-aspira400 mb-[1.5rem] mt-[1.968rem]'>{title}</div> : <div className='text-[1.375rem] font-aspira400 mb-[1.5rem] mt-[1.968rem]'>{EXPLANATION}</div>}
          <div className='text-[1rem] text-[#474358] font-aspira400'>
            <PlayerContent content={explanation} />
          </div>
        </div>

        {!title && (
          <div className='fixed bottom-0 cursor-pointer bg-white h-[5.125rem] w-[85%]'>
            <div
              className='flex items-center cursor-pointer'
              onClick={async () => {
                handleFlipCard();
              }}
              aria-hidden
            >
              <div className='text-[#341C8D] text-[1.25rem] font-aspira700 underline underline-offset-4'>{BACK_TO_QUESTION}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizExplanation;
