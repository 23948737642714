// eslint-disable-next-line import/no-extraneous-dependencies
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { sortArray } from '../utils';
import { createClientDelivery, createClientPreview } from './contentful.service';
import {
  IQUIZ_CONTENTFUL, CONTENTFUL_IMODULE, CONTENT, CONTENTFUL_ISCREEN,
} from '../interfaces';

const client = () => {
  const path = window.location.pathname;
  if (path.startsWith('/preview')) {
    return createClientPreview;
  }
  return createClientDelivery;
};

const getTip = async (id: string) => {
  try {
    const tipEntry: any = await client().getEntry(id);
    if (tipEntry && tipEntry.fields) {
      const tipJson = {
        explanation: Document,
        title: 'Tip',
      };
      if (tipEntry.fields.Explanation) tipJson.explanation = tipEntry.fields.Explanation;
      if (tipEntry.fields.tipTitle) tipJson.title = tipEntry.fields.tipTitle;
      return tipJson;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getQuiz = async (id: string) => {
  try {
    const quizEntry: any = await client().getEntry(id);
    if (quizEntry && quizEntry.fields) {
      const quizJson: IQUIZ_CONTENTFUL = {
        material: {
          question: '',
          answers: ['', ''],
          correctAnswer: '',
          explanation: {
            data: {},
            content: [{
              data: {},
              content: [{
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              }],
              nodeType: BLOCKS.PARAGRAPH,
            }],
            nodeType: BLOCKS.DOCUMENT,
          },
        },
      };
      if (quizEntry.fields.type) quizJson.type = quizEntry.fields.type;
      if (quizEntry.fields.question) quizJson.material!.question = quizEntry.fields.question;
      if (quizEntry.fields.answers) quizJson.material!.answers = quizEntry.fields.answers;
      if (quizEntry.fields.correctAnswer) quizJson.material!.correctAnswer = quizEntry.fields.correctAnswer;
      if (quizEntry.fields.explanation) quizJson.material!.explanation = quizEntry.fields.explanation;
      return quizJson;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getScreens = async (mod: CONTENTFUL_IMODULE) => {
  const screensArray: {
        title?: string;
        id: string;
        timeEstimation?: string;
        image?: string;
        markdown?: CONTENT;
        quiz?: IQUIZ_CONTENTFUL | null,
        markdownImage?: string
    }[] = [];
  if (!mod) return screensArray;
  await Promise.all(mod.fields.screens.map(async (screen: CONTENTFUL_ISCREEN) => {
    if (!screen.fields.title || !screen.fields.slug || !screen.fields.timeEstimation) console.log('error in getScreen');
    const screenJson: {
            title?: string,
            id: string,
            timeEstimation?: string,
            image?: string,
            markdown?: CONTENT,
            quiz?: IQUIZ_CONTENTFUL | null,
            markdownImage?: string
            quizTip?: { explanation: {}; title: string } | null,
        } = {
          id: screen.fields.slug,
          title: screen.fields.title,
          timeEstimation: screen.fields.timeEstimation,
        };
    if (screen.fields.image) screenJson.image = `https:${screen.fields.image.fields.file.url}`;
    if (screen.fields.content) screenJson.markdown = screen.fields.content;
    if (screen.fields.quiz) screenJson.quiz = await getQuiz(screen.fields.quiz.sys.id);
    if (screen.fields.tip) screenJson.quizTip = await getTip(screen.fields.tip.sys.id);
    screensArray.push(screenJson);
  }));
  return sortArray(screensArray, '/');
};

const getModules = async (modules: CONTENTFUL_IMODULE[]) => {
  const moduleArray: { id: string; displayName: string; screens: {} }[] = [];
  if (!modules) return moduleArray;
  await Promise.all(modules.map(async module => {
    const mod: any = await client().getEntry(module.sys.id);
    const moduleId = mod.fields.slug;
    const { displayName } = mod.fields;
    moduleArray.push({
      id: moduleId,
      displayName,
      screens: await getScreens(mod),
    });
  }));
  return sortArray(moduleArray, '-');
};
const createCurriculum = async (id: string, displayName: string, modules: any[]) => {
  if (id && displayName) {
    return {
      id,
      displayName,
      modules: await getModules(modules),
    };
  }
  return null;
};

const getEntry = (courseID: string) => client().getEntry(courseID);

export default {
  getEntry,
  createCurriculum,
};
