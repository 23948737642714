import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QuizOptions } from '../index';
import { IQuiz } from '../../interfaces';
import {
  CHANGE_SCREEN_OPTION, NEXT_BTN, QUIZ_SUBMISSION_OPTIONS, QUIZ_TYPES, STATIC_QUIZ_STRINGS,
} from '../../constants';

interface IProps {
  quiz: IQuiz;
  isExplanationViewed: boolean;
  handleChangeScreen: (context: string) => void;
  handleFlipCard: () => void;
  userAnswered: (boolean: boolean) => void;
}

const Quiz = ({
  quiz, isExplanationViewed, handleChangeScreen, handleFlipCard, userAnswered,
}: IProps) => {
  const { moduleId, screenNum } = useParams();
  const [userAnswer, setUserAnswer] = useState<string>('');
  const [quizStatus, setQuizStatus] = useState<string>('');
  const [explanationSeen, setExplanationSeen] = useState<boolean>(false);

  const { SHOW_EXPLANATION } = STATIC_QUIZ_STRINGS;
  const { IS_SELECTED } = QUIZ_SUBMISSION_OPTIONS;
  const { MULTIPLE_CHOICE, SURVEY } = QUIZ_TYPES;

  const { type, material: { correctAnswer, answers, question } } = quiz;

  const handleQuizSubmission = (status: string) => {
    switch (status) {
      case IS_SELECTED:
        if (type === SURVEY) {
          setTimeout(() => {
            handleFlipCard();
          }, 500);
        }
        setQuizStatus(IS_SELECTED);
        break;
      case NEXT_BTN:
        if (isExplanationViewed) handleFlipCard();
        handleChangeScreen(CHANGE_SCREEN_OPTION.FORWARD_ARROW);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setUserAnswer('');
    setQuizStatus('');
    setExplanationSeen(false);
  }, [moduleId, screenNum]);

  useEffect(() => {
    if (userAnswer && !quizStatus) handleQuizSubmission(IS_SELECTED);
    if (userAnswer) {
      userAnswered(true);
    } else {
      userAnswered(false);
    }
  }, [userAnswer]);

  useEffect(() => {
    if (isExplanationViewed) {
      setTimeout(() => {
        if (type === SURVEY) {
          setUserAnswer(userAnswer);
        } else {
          setUserAnswer(correctAnswer);
          setExplanationSeen(true);
        }
        setQuizStatus(IS_SELECTED);
      }, 1000);
    }
  }, [isExplanationViewed]);

  return (
    <div className={`w-full h-full relative ${isExplanationViewed ? 'hidden' : ''}`}>
      <div className='bg-white shadow-lastStudiedBox rounded-[0.5rem] h-full w-full p-[3.5rem] overflow-y-auto'>
        <div className='fixed top-0 left-1 w-[99%] h-[3.75rem] bg-white z-50'>
          <div className='absolute bottom-[0.25rem] w-[4rem] h-[0.468rem] bg-orange rounded-[62.5rem] ml-[3rem]' />
        </div>

        <div className='text-[1.375rem] font-aspira400 mb-[1.5rem] mt-[1.968rem]'>{question}</div>
        <QuizOptions
          quizType={type}
          quizStatus={quizStatus}
          answers={answers}
          correctAnswer={correctAnswer}
          handleClick={setUserAnswer}
          isExplanationViewed={explanationSeen}
        />

        <div className='fixed bottom-0 cursor-pointer bg-white h-[5.125rem] w-[85%]'>
          {type === MULTIPLE_CHOICE && (
          <div
            className='flex items-center cursor-pointer'
            onClick={async () => {
              handleFlipCard();
            }}
            aria-hidden
          >
            <div className='text-[#341C8D] text-[1.25rem] font-aspira700 underline underline-offset-4'>{SHOW_EXPLANATION}</div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quiz;
