import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackToDashboard, Navbar } from '../index';
import { ERROR_STRINGS, ERROR_ICON as errorImage } from '../../constants';

const Error = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const { TITLE, SUB_TITLE } = ERROR_STRINGS;
  return (
    <div>
      <Navbar />
      <div className='w-screen md:h-screen flex justify-center items-center max-md:mt-[7rem]'>
        <div className='flex max-md:flex-col items-center'>
          <img
            src={errorImage}
            alt='yeti'
            className='w-[16.188rem] max-md:w-[13.188rem] h-[16.875rem] max-md:h-[13.875rem] md:mr-10'
          />
          <div
            className='max-md:w-[17rem] w-[29.813rem] max-md:mt-8 max-md:flex max-md:flex-col max-md:items-center'
          >
            <div className='text-[2.25rem] max-md:text-[1.5rem] font-bold'>{TITLE}</div>
            <div className='text-[2rem] max-md:text-[1.25rem] mb-10 max-md:text-center'>
              {SUB_TITLE}
            </div>
            <BackToDashboard navigation={() => navigate(`/syllabus/${courseId}`)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
