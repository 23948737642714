import { CLOCK_ICON as clockIcon } from '../../constants';

interface IProps {
  className: string;
  context: String;
}

const Clock = ({ className, context }: IProps) => (
  <div className='h-full flex'>
    <img className='h-full mr-2.5' src={clockIcon} alt='clockIcon' />
    <div className={`${className} h-full flex items-center`}>
      {context}
    </div>
  </div>
);

export default Clock;
