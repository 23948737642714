import React, { useState } from 'react';
import { NAVBAR_OPTIONS, NAV_LINK_NAMES } from '../../constants';
import profileImage from '../../assets/images/profile-menu.svg';
import profileImageGrey from '../../assets/images/profile-menu-grey.svg';

const NavOptions = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { HELP } = NAV_LINK_NAMES;

  const handleClick = (linkName: string) => {
    switch (linkName) {
      case HELP:
        window.location.replace('https://fkuo1wtze66.typeform.com/to/w2K7aTqL');
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div>
        <div className='px-2 cursor-pointer' onClick={() => setIsOpen(!isOpen)} aria-hidden>
          {isOpen
            ? <img src={profileImageGrey} alt='grey-profile-menu' />
            : <img src={profileImage} alt='profile-menu' className='relative fill-[#eeeef1]' />}
        </div>

        {isOpen && (
        <div className='pl-[0.25rem] absolute right-[4%] w-[10.188rem] rounded-[0.5rem] shadow-lastStudiedBox bg-[#FFF]'>
          {NAVBAR_OPTIONS.map(({ link, icon }, index) => (
            <div
              className={`py-[0.5rem] flex items-center cursor-pointer ${index !== NAVBAR_OPTIONS.length - 1 && ('mb-[1rem]')}`}
              onClick={() => handleClick(link)}
              aria-hidden
            >
              <img src={icon} alt='logout' className='mx-[0.75rem] h-[1.875rem] w-[1.875rem]' />
              <div className='text-[1rem] font-aspira550'>{link}</div>
            </div>
          ))}
        </div>
        )}
      </div>
    </div>
  );
};

export default NavOptions;
