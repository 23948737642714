import {
  ICourse, IEnrollment, IProgress, IModule, IModuleWithoutScreen,
} from '../interfaces';

export const percentages = (values: number[], totalValue: number) => values.map((length: number) => (length / totalValue) * 100);
export const percentage = (values: number, totalValue: number) => ((values / totalValue) * 100);

// TODO - remove any's when using this function or remove func if not needed
export const courseCalculation = (course: any) => {
  const modulesLengths: number[] = [];
  course.modules.map((module: any, index: number) => module.screens.forEach(({ content }: any) => {
    if (modulesLengths[index]) modulesLengths[index] += content.timeEstimation;
    else modulesLengths[index] = content.timeEstimation;
  }));

  const courseLength = modulesLengths.reduce((a, b) => a + b);
  return { courseLength, modulesLengths: percentages(modulesLengths, courseLength) };
};

export const lastStringCharToNumber = (stg: string) => +stg![stg!.length - 1];

export const isObjEmpty = (obj: object) => Object.keys(obj).length === 0;

export const decodeIdToken = (idToken: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, token] = idToken.slice(idToken.indexOf('=') + 1).split('.');
  return JSON.parse(atob(token));
};

export const courseStructureView = (courses: ICourse[]) => {
  const structuredCourses: any = {};
  courses.forEach(course => {
    if (structuredCourses[course.career_track]) structuredCourses[course.career_track].push(course);
    else structuredCourses[course.career_track as keyof typeof structuredCourses] = [course];
  });
  return structuredCourses;
};

export const courseStructureBasedEnrollmentView = (enrollments: IEnrollment[]) => {
  const structuredCourses: any = {};
  enrollments.forEach(enrollment => {
    if (structuredCourses[enrollment.course.career_track]) structuredCourses[enrollment.course.career_track].push(enrollment);
    else structuredCourses[enrollment.course.career_track as keyof typeof structuredCourses] = [enrollment];
  });
  return structuredCourses;
};
export const sortArray = (array: { id: string }[], spliter: string) => array.sort((a, b) => +a.id.split(spliter)[1] - +b.id.split(spliter)[1]);

export const returnLayout = (modules: any[]) => {
  if (modules) {
    const progress: IProgress[] = [];
    modules.forEach(({ id: modId, screens }: IModule) => {
      if (modId) {
        progress.push({ [modId]: { total_screens: screens.length, completed_screens: 0, completed: false } });
      } else progress.push({ [modId]: { total_screens: screens.length, completed_screens: 0, completed: false } });
    });
    return progress;
  }
  return null;
};
export const returnStructure = (structure: any) => {
  if (structure && structure.modules) {
    const starcu = structure.modules.map((module: IModuleWithoutScreen) => {
      module.screensLength = module.screens!.length;
      delete module.screens;
      return module;
    });
    console.log('starcu', starcu);
    return starcu;
  }
  return null;
};
