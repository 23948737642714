import React from 'react';
import { PercentageProgressBar } from '../index';

interface IProps {
  denominator: number;
  progress: {
    percents: number;
    subTitle: string;
  }
  progressBarIndex: number;
  currentPage: number
}

const PlayerProgressBar = ({
  denominator,
  progress,
  progressBarIndex,
  currentPage,
}:IProps) => (
  <div>
    {denominator !== 0 && (
    <PercentageProgressBar
      lineStyle='h-[1.125rem] rounded-full cursor-pointer'
      percents={progress.percents}
      conStyle={`transform transition-[width] duration-[400ms] ease-linear mx-[0.13rem] ${progressBarIndex === currentPage - 1 ? 'w-[12.5rem]' : 'w-[1.125rem]'}`}
    />
    )}
  </div>
);
export default PlayerProgressBar;
