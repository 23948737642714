import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2';
import {
  Button, ModuleInfoCon, Navbar, PlayerContent as Content,
} from '../../components';
import { PLAYER, PREVIEW_PLAYER } from '../route_links';
import {
  STATIC_SKILLS_CARD_STRINGS,
  GIRL_WITH_ICON as girlWithLaptop,
  AUTHORING_TOKEN,
  PASSWORD,
  SWEETALERT_PUBLISH,
  SWEETALERT_SUCCESS,
  SWEETALERT_ERROR,
  PUBLISH_TIMEOUT, ENVIRONMENT,
} from '../../constants';
import {
  ICourse,
  IEnrollmentPosition, IScreen, ITimeEstimations,
} from '../../interfaces';
import { courseStructureStore } from '../../store/CourseStructure';
import { returnLayout, returnStructure } from '../../utils';
import contentfulService from '../../services/contentfulTools.service';
import { navigationBarStore } from '../../store/NavigationBarStore';
import { setCourseCurriculum } from '../../store/CourseCurriculum';

const Syllabus = () => {
  const navigate = useNavigate();
  const { preview, courseId } = useParams();
  const { RESUME_LEARNING, PUBLISH_MASTER } = STATIC_SKILLS_CARD_STRINGS;
  const { title, subTitle, confirmButton } = SWEETALERT_PUBLISH;

  const [position, setPosition] = useState<IEnrollmentPosition>();
  const [course, setCourse] = useState<ICourse>();

  const [structure, setStructure] = courseStructureStore((state) => [state.structure, state.setCourseStructure]);
  const [setUserPosition] = navigationBarStore((state) => [state.setUserPosition]);
  const [setCurriculum] = setCourseCurriculum((state) => [state.setCourseCurriculum]);

  useEffect(() => {
    async function returnEntry() {
      const entry = await contentfulService.getEntry(courseId!);
      // @ts-ignore
      const { displayName, description, syllabusDescription } = entry.fields.course.fields;
      setCourse({
        id: 0,
        name: displayName,
        description,
        syllabus_description: syllabusDescription,
        career_track: '',
        visible: true,
        current_course_version_id: 1,
      });
      const courseCurriculum = await contentfulService.createCurriculum(entry.sys.id, entry.fields.internalName as string, entry.fields.modules as any);
      setCurriculum(courseCurriculum);
      const moduleTimeEstimations: ITimeEstimations[] = [];
      // @ts-ignore
      courseCurriculum!.modules.forEach(({ id, screens }: {id: string, screens: IScreen[]}) => {
        if (position && +id.slice(-1)) {
          moduleTimeEstimations.push({ [id]: 0 });
        } else if (position && +position.current_module.slice(-1) === +id.slice(-1)) {
          let completedSum = 0;
          let fullModSum = 0;
          screens.forEach(({ timeEstimation }, index) => {
            if (index < +position.current_screen) completedSum += timeEstimation!;
            fullModSum += timeEstimation!;
          });
          moduleTimeEstimations.push({ [id]: fullModSum - completedSum });
        } else {
          let fullModSum = 0;
          screens.forEach(({ timeEstimation }) => { fullModSum += timeEstimation!; });
          moduleTimeEstimations.push({ [id]: fullModSum });
        }
      });
      // @ts-ignore
      setPosition({ current_module: courseCurriculum.modules[0].id, current_screen: '0', progress: returnLayout(courseCurriculum.modules) });
      setUserPosition({ courseId: '', moduleIndex: 0, screenIndex: 0 });
      const modulesWithNoScreens = returnStructure(JSON.parse(JSON.stringify(courseCurriculum)));
      setStructure(modulesWithNoScreens);
    }
    returnEntry();
  }, []);

  const handlePublishCourse = async () => {
    await Swal.fire({
      title,
      text: subTitle,
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: confirmButton,
      showLoaderOnConfirm: true,
      preConfirm: async (password) => {
        if (PASSWORD === password) {
          const entry = await contentfulService.getEntry(courseId!);
          // @ts-ignore
          const data = { fromEnvironment: ENVIRONMENT.STAGE, toEnvironment: ENVIRONMENT.PRODUCTION, entryId: entry.fields!.course!.sys.id };
          await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT as string, data, {
            headers: {
              Authorization: AUTHORING_TOKEN,
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Request-Method': 'POST',
            },
            timeout: PUBLISH_TIMEOUT,
          })
            .then(response => {
              if (response.status === 200) {
                Swal.fire({
                  icon: 'success',
                  title: SWEETALERT_SUCCESS.title,
                });
              }
            })
            .catch(() => {
              Swal.fire({
                icon: 'error',
                title: SWEETALERT_ERROR.title,
              });
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleMoveIntoPlayer = async () => {
    if (preview) {
      navigate(generatePath(PREVIEW_PLAYER, {
        preview,
        courseId: courseId!,
        moduleId: 'module-1',
        screenNum: '0',
      }));
    }
    navigate(generatePath(PLAYER, {
      courseId: courseId!,
      moduleId: 'module-1',
      screenNum: '0',
    }));
  };

  return (
    <div className='flex flex-col items-center h-screen'>
      <Navbar />
      <div className='flex pt-[6rem] max-xl:w-[75%] max-sm:w-[95%] w-[80%] flex justify-between items-center flex-grow max-xl:flex-col'>
        <div className='max-sm:w-[95%] max-xl:w-[90%] w-[50%] flex flex-col justify-center max-xl:my-3'>
          <div className='h-full flex flex-col justify-between'>
            <div className='text-[1.5rem] font-bold leading-[1.75rem] max-xl:p-2 mb-[1.5rem]'>{course ? course.name : ''}</div>
            <div className='xl:w-[80%] max-xl:p-2 mb-[1.5rem]'>{course ? <Content content={course.syllabus_description} /> : ''}</div>
            <div>
              {structure.map(({ displayName }, index: number) => (
                <ModuleInfoCon
                  key={displayName}
                  timeEstimations={0}
                  moduleIndex={index + 1}
                  moduleName={displayName}
                  progress={{ completed_screens: 0, total_screens: 0 }}
                />
              ))}
            </div>

            <div className='w-full max-xl:py-[2rem] xl:mb-[2rem] flex max-md:justify-center xl:mt-10'>
              <Button
                className='w-48 py-1 bg-orange text-white rounded-[3rem] max-xl:my-4 max-xl:p-2'
                context={RESUME_LEARNING}
                handleClick={() => handleMoveIntoPlayer()}
              />
              <Button
                className='w-48 ml-[10rem] bg-orange text-white rounded-[3rem] max-xl:my-4 max-xl:p-2'
                context={PUBLISH_MASTER}
                handleClick={() => handlePublishCourse()}
              />
            </div>
          </div>
        </div>
        <div className='max-lg:w-[95%] w-[50%] flex justify-end max-xl:justify-center max-xl:my-3'>
          <img className='min-h-[20.625rem]' src={girlWithLaptop} alt='student' />
        </div>
      </div>
    </div>
  );
};

export default Syllabus;
