interface IProps {
  className?: string;
  numerator: number;
  denominator: number
}

const CurrentPage = ({ className, numerator, denominator }: IProps) => (
  <div className={className}>
    {`${numerator} / ${denominator}`}
  </div>
);
export default CurrentPage;
