import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Clock, CurrentPage } from '../index';
import {
  ESTIMATION, STATIC_SKILLS_CARD_STRINGS, TIME_OPTIONS, GREEN_CHECK_ICON as greenCheckIcon, FIRST_PAGE_IN_MODULE,
} from '../../constants';
import { IPosition } from '../../interfaces';
import { PLAYER, PREVIEW_PLAYER } from '../../pages/route_links';

interface IProps {
  moduleIndex: number;
  moduleName: string;
  progress: IPosition
  timeEstimations: number
}

const ModuleInfoCon = ({
  moduleIndex, moduleName, timeEstimations, progress: { completed_screens, total_screens },
}: IProps) => {
  const navigate = useNavigate();
  const { preview, courseId } = useParams();

  const { MODULE } = STATIC_SKILLS_CARD_STRINGS;
  const { MINUTES } = TIME_OPTIONS;

  const handleMoveToCompletedMod = (modIndex: number) => {
    if (preview) {
      navigate(generatePath(PREVIEW_PLAYER, {
        preview,
        courseId: courseId!.toString(),
        moduleId: `module-${modIndex}`,
        screenNum: FIRST_PAGE_IN_MODULE,
      }));
    } else {
      navigate(generatePath(PLAYER, { courseId: courseId!.toString(), moduleId: `module-${modIndex}`, screenNum: FIRST_PAGE_IN_MODULE }));
    }
  };

  return (
    <div
      className={`border border-whiteGrey border-1 flex justify-between pl-3 pr-5 max-lg:px-2 
        ${completed_screens === total_screens ? 'h-[3.5rem] max-lg:h-[4.5rem] items-center cursor-pointer hover:border-brightGreen' : 'h-[4.5rem] max-lg:h-[6rem]'}`}
      aria-hidden
      onClick={() => (completed_screens === total_screens ? handleMoveToCompletedMod(moduleIndex) : null)}
    >
      <div className={`flex flex-col h-full py-2 ${completed_screens === total_screens ? 'justify-center' : 'justify-between'}`}>
        <div className='flex items-center max-lg:flex-col max-lg:items-start'>
          <div className='text-[0.875rem] mr-2'>{`${MODULE} ${moduleIndex}:`}</div>
          <div className='max-sm:text-[0.875rem] max-sm:font-semibold font-bold text-[1rem]'>{moduleName}</div>
        </div>

        {total_screens !== completed_screens && (
        <div className='flex items-center'>
          <div className='h-[0.875rem] mr-1.5'>
            <Clock className='text-[0.875rem] ml-1' context={`${timeEstimations} ${MINUTES}`} />
          </div>
          <div className='max-lg:text-xs text-[0.875rem] text-midGrey'>{ESTIMATION}</div>
        </div>
        )}
      </div>

      {total_screens === completed_screens
        ? <img src={greenCheckIcon} alt='check' className='h-5' />
        : (
          <div className='flex items-center h-full'>
            <CurrentPage className='max-sm:text-[0.875rem] text-[1rem] font-bold' numerator={completed_screens} denominator={total_screens} />
          </div>
        )}
    </div>
  );
};

export default ModuleInfoCon;
