import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document } from '@contentful/rich-text-types';
import {
  PlayerTitle, EstimatedTime, PlayerContent,
} from '../index';

interface IProps {
  title?: string;
  timeEstimation?: number;
  markdown?: Document;
}

const LeftPlayer = ({ markdown, timeEstimation, title }: IProps) => (
  <div className='flex flex-col w-full mt-[7.5rem] pl-[3.75rem] pr-[1.5rem] mb-[10rem]'>
    {title && (<div className=''><PlayerTitle title={title} /></div>)}

    {timeEstimation && (<div className=''><EstimatedTime timeEstimation={timeEstimation} /></div>)}

    {markdown && (<div className='mt-[3rem]'><PlayerContent content={markdown} /></div>)}
  </div>
);

export default LeftPlayer;
