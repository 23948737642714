import React, { useEffect, useState } from 'react';
import expandIcon from '../../assets/images/expand-image.svg';
import { Portal } from '../index';

interface IProps {
  src: string;
  alt?: string;
}

const PlayerImage = ({
  src, alt,
}:IProps) => {
  const [isImageExpanded, setIsImageExpanded] = useState(false);

  useEffect(() => {
    const mainDiv = document.getElementById('root');
    if (isImageExpanded) {
      mainDiv!.classList.add('overflow-hidden');
    } else {
      mainDiv!.classList.remove('overflow-hidden');
    }
  }, [isImageExpanded]);

  return (
    <div>
      <div className='w-full flex justify-center items-center relative'>
        <img
          className='rounded-[0.75rem] max-w-full'
          src={src}
          alt={alt}
        />

        <div
          className='absolute bg-white right-[1.5rem] bottom-[1.5rem] h-[3rem] w-[3rem] rounded-full border border-1 border-grey flex justify-center items-center'
          aria-hidden
          onClick={() => setIsImageExpanded(true)}
        >
          <img src={expandIcon} alt='expand' className='h-[1.25rem] w-[1.25rem] cursor-pointer' />
        </div>
      </div>

      <Portal isOpen={isImageExpanded}>
        <div
          className='z-10 fixed right-0 left-0 top-0 w-full h-full bg-black bg-opacity-80'
          aria-hidden
          onClick={() => setIsImageExpanded(false)}
        >
          <div className='cursor-pointer flex h-full w-full justify-center items-center'>
            <div className='w-[100%] flex justify-center items-center'>
              <img
                className='max-w-[70%] min-w-[50%] max-h-[90%]'
                src={src}
                alt='player'
              />
            </div>
          </div>
        </div>
      </Portal>
    </div>
  );
};

export default PlayerImage;
