import React, { ReactNode } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Bold,
  Code,
  HeadingFive,
  HeadingFour,
  HeadingOne,
  HeadingSix,
  HeadingThree,
  HeadingTwo, HR,
  Italic, OrderedList, Quote, Sub, Table, TableData, TableHeader, TableRow,
  Text,
  Underline,
  UnorderedList,
  defaultInline,
} from 'courseTester/src/components/ContentTypes';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Block, BLOCKS, Inline, INLINES, MARKS, Document,
} from '@contentful/rich-text-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { PlayerImage } from '../index';

export interface NodeRenderer {
  (node: Block | Inline, children: ReactNode): ReactNode;
}

interface IOptions {
  renderNode: {[k: string]: NodeRenderer};
  renderMark: {[k: string]: (text: ReactNode) => ReactNode};
}

interface IProp {
  content: Document
}

const PlayerContent = ({ content } : IProp) => {
  // TODO - remove any
  const options: IOptions = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
      [MARKS.UNDERLINE]: (text) => <Underline>{text}</Underline>,
      [MARKS.CODE]: (text) => <Code>{text}</Code>,
      [MARKS.SUPERSCRIPT]: (text) => <Sub>{text}</Sub>,
      [MARKS.SUBSCRIPT]: (text) => <Sub>{text}</Sub>,
    },
    renderNode: {
      [BLOCKS.DOCUMENT]: (node, children) => children,
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.HEADING_1]: (node, children) => <HeadingOne>{children}</HeadingOne>,
      [BLOCKS.HEADING_2]: (node, children) => <HeadingTwo>{children}</HeadingTwo>,
      [BLOCKS.HEADING_3]: (node, children) => <HeadingThree>{children}</HeadingThree>,
      [BLOCKS.HEADING_4]: (node, children) => <HeadingFour>{children}</HeadingFour>,
      [BLOCKS.HEADING_5]: (node, children) => <HeadingFive>{children}</HeadingFive>,
      [BLOCKS.HEADING_6]: (node, children) => <HeadingSix>{children}</HeadingSix>,
      [BLOCKS.UL_LIST]: (node, children) => <UnorderedList>{children}</UnorderedList>,
      [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
      [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
      [BLOCKS.TABLE]: (node, children) => <Table>{children}</Table>,
      [BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <TableHeader>{children}</TableHeader>,
      [BLOCKS.TABLE_CELL]: (node, children) => <TableData>{children}</TableData>,
      [BLOCKS.HR]: (node, children) => <HR>{children}</HR>,
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <div>{children}</div>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // eslint-disable-next-line react/destructuring-assignment
        const { file: { url }, description } = node.data.target.fields;
        return (
          <PlayerImage
            src={`https://${url}`}
            alt={description}
          />
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node) => defaultInline(INLINES.ASSET_HYPERLINK, node as Inline),
      [INLINES.ENTRY_HYPERLINK]: (node) => defaultInline(INLINES.ENTRY_HYPERLINK, node as Inline),
      [INLINES.EMBEDDED_ENTRY]: (node) => defaultInline(INLINES.EMBEDDED_ENTRY, node as Inline),
      // eslint-disable-next-line react/destructuring-assignment
      [INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri}>{children}</a>,
    },
  };
  return (
    <div className='whitespace-pre-wrap leading-[normal]'>{documentToReactComponents(content, options)}</div>
  );
};

export default PlayerContent;
